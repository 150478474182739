import SiteWrapper from "../../components/wrapper.tsx";
import { Helmet } from "react-helmet";
import ogImage from "../../assets/images/pagerduty_dxable_og.png";

import MdRenderer from "../../components/mdrender";
import * as md from "../../components/mdrender.module.sass";
import TitleBlock from "../../components/title";

import "./it-operations@gen.scss";

const GeneratedProductItOperations = () => {
  return (
    <SiteWrapper isLandingPage={false}>
      <Helmet>
        <title>ITの運用 - PagerDuty正規代理店 DXable</title>

        <script
          type="text/javascript"
          defer
          src="https://app.secureprivacy.ai/script/639bffa95b4c7ff65172b36d.js"
        ></script>
        <meta
          property="twitter:description"
          content="Use Caseの中からIT運用の改革の支援について説明しています。ITOpsチームが、より迅速な解決のための適切なプロセスを促し、今後起こりうる問題を防ぐために学習させるなど。"
        />
        <meta property="twitter:card" content="summary_large_image" />
        <meta
          name="description"
          content="Use Caseの中からIT運用の改革の支援について説明しています。ITOpsチームが、より迅速な解決のための適切なプロセスを促し、今後起こりうる問題を防ぐために学習させるなど。"
        />

        <meta
          property="og:title"
          content="ITの運用 - PagerDuty正規代理店 DXable"
        />

        <meta
          property="og:description"
          content="Use Caseの中からIT運用の改革の支援について説明しています。ITOpsチームが、より迅速な解決のための適切なプロセスを促し、今後起こりうる問題を防ぐために学習させるなど。"
        />
        <meta
          property="og:url"
          content="https://pagerduty.dxable.com/product/it-operations/"
        />
        <meta
          property="twitter:url"
          content="https://pagerduty.dxable.com/product/it-operations/"
        />
        <meta property="og:type" content="website" />
        <meta
          property="og:image"
          content={"https://pagerduty.dxable.com" + ogImage}
        />
        <meta
          property="twitter:image"
          content={"https://pagerduty.dxable.com" + ogImage}
        />
      </Helmet>

      <TitleBlock
        classNames="md:mb-[80px] mb-[40px]"
        textYellow="Products"
        textGreen="ITの運用"
        overview="Use Caseの中からIT運用の改革の支援について説明しています。ITOpsチームが、より迅速な解決のための適切なプロセスを促し、今後起こりうる問題を防ぐために学習させるなど。"
      />

      <div className="itoperationsWrapper">
        <div className="itoperations-container">
          <div className="center-1 mt0">
            <h3 className="center-title">
              インシデントが発生したら
              <br />
              PagerDutyが御社をバックアップ
            </h3>
            <p className="center-para">
              デジタル時代において、組織は新しい技術を採用して高まる顧客の期待に応えていますが、信頼を犠牲にしてまで敏捷性を高めることはできません。
              <br />
              PagerDutyは、ビジネスに影響を与えるインシデントのリスクを軽減しながら、業務を近代化するのに役立ちます。業務の混乱をなくし、イノベーションのための時間を取り戻してください。
            </p>
          </div>
          <div className="center-2">
            <h3 className="center-title">
              エンドツーエンドのインシデント管理を自動化し、
              <br />
              優れたカスタマーエクスペリエンスを確保します。
            </h3>
            <p className="center-para">
              複雑なインフラストラクチャを管理することは、これまでそれほど重要性が高くありませんでした。顧客に影響を与えるサービスの低下やサービス停止状態が企業に数千万〜数億円以上のコストをかける可能性がある事態になった場合に、顧客がチケットを発行するのを待っているようでは遅すぎます。
              <br />
              PagerDutyは、ITOpsチームが、より迅速な解決のための適切なプロセスを促し、今後起こりうる問題を防ぐために学習させることで、運用の改革を支援します。
            </p>
          </div>
          <div className="center-1">
            <h3 className="center-title mb-[40px]">
              インシデント対応のライフサイクル
            </h3>
            <ul className="list-container">
              <li className="list-items">
                <img src="https://strapi-admin.pagerduty.digitalstacks.net/uploads/ul_list_disc_black_e25e2e6933.png" />
                インフラストラクチャ内、つまりツールチェーン全体での相関イベントすべてのビューを提供することで、
                潜在的な問題、依存関係、インシデントへの影響、根本的な原因を迅速に特定します。
              </li>
              <li className="list-items">
                <img src="https://strapi-admin.pagerduty.digitalstacks.net/uploads/ul_list_disc_black_e25e2e6933.png" />
                関連するアラートをインシデントごとにグループ化し、イベントルールを使用してアクション不要なアラートを抑制しながら、問題に対処できる適切な人員に自動的にルーティングします。
              </li>
              <li className="list-items">
                <img src="https://strapi-admin.pagerduty.digitalstacks.net/uploads/ul_list_disc_black_e25e2e6933.png" />
                修復情報を提供して、専門家を集め、解決に向けてコラボレートし、ビジネスステークホルダーに状況を提供することで、
                重大なインシデント対応の適切なワークフローを活用して容易に問題解決することができます。
              </li>
              <li className="list-items">
                <img src="https://strapi-admin.pagerduty.digitalstacks.net/uploads/ul_list_disc_black_e25e2e6933.png" />
                システムの回復力と将来の対応を継続的に改善するために事後検証を合理的に行えるようにします。
              </li>
            </ul>
          </div>
          <div className="max-930px-img">
            <img
              className="object-contain"
              src="https://ik.imagekit.io/tcqno3mrr/PagerDuty/Product/IT_Operations/process_diagram_it_ops_1_521ae4e5cc.png?ik-sdk-version=javascript-1.4.3&updatedAt=1672998586395&tr=q-100,f-webp"
              alt="インシデント対応のライフサイクル"
            />
          </div>
          <div className="center-2">
            <h3 className="center-title">近代的な運用のベストプラクティス</h3>
            <p className="center-para">
              ベストプラクティスのインシデント管理により、運用上の俊敏性と成熟度を向上させるます
            </p>
          </div>
          <div className="two-col-media-contents">
            <div className="text-part">
              <p className="title">迅速な解決を実現</p>
              <p className="para">
                チケットが発行された時点でようやく対応したのでは、遅すぎます。1分毎のサービス低下が、何千ドルもの影響を及ぼし、顧客を逃してしまうことになります。
              </p>
              <p className="para">
                PagerDutyを使用すると、適切な状況情の報提供と強力な自動化によって、解決までのスピードを最大限に高めることができます。PagerDutyは、DevOpsのベストプラクティスを促進し、組織が高性能なサービスを提供して、ブランドを保護できるようにするための重要な役目を担っています。
              </p>
              <p className="para">
                インフラストラクチャのパフーマンスを包括的に把握することを容易にし、根本的な原因を把握して、問題の優先順位をつけ、問題解決を自動化することで、1分1秒を争う状況での手作業で浪費される時間を最小限に抑えまォす。
              </p>
            </div>
            <div className="media-contents-part">
              <img
                src="https://ik.imagekit.io/tcqno3mrr/PagerDuty/Product/IT_Operations/it_occ2x_071997375f.png?ik-sdk-version=javascript-1.4.3&updatedAt=1672998586347&tr=w-720,q-100,f-webp"
                alt="迅速な解決を実現"
              />
            </div>
          </div>
          <div className="two-col-media-contents">
            <div className="text-part">
              <p className="title">
                問題を未然に防ぎ
                <br />
                本来の作業時間を取り戻す
              </p>
              <p className="para">
                チケットが発行されて顧客に影響が及ぶ前に問題を回避します。
              </p>
              <p className="para">
                学習によるシステムとインシデント対応プロセスの改善を学習することによって、予期しない問題の回避が容易になります。PagerDutyは、状況を正しく浮き彫りにし、適切なリソースを活用して、事後検証の合理化を図ることで、インシデント管理のベストプラクティスの自動化を促します。
              </p>
              <p className="para">
                学習させることのシステム化を容易にすることで、次にシステム停止が発生した場合にチームが不意打ちをつかれることはありません。チームの生産性の向上は、問題を解決するのではなく、革新を差別化するビジネスに費やす時間を増やすことを意味します。
              </p>
            </div>
            <div className="media-contents-part pr30">
              <img
                src="https://ik.imagekit.io/tcqno3mrr/PagerDuty/Product/IT_Operations/timeline2x_788ab615ab.png?ik-sdk-version=javascript-1.4.3&updatedAt=1672998586412&tr=w-720,q-100,f-webp"
                alt="問題を未然に防ぎ 本来の作業時間を取り戻す"
              />
            </div>
          </div>
          <div className="two-col-media-contents margin-bottom">
            <div className="text-part">
              <p className="title">知識は力なり</p>
              <p className="para">
                既存のプロセスとの一貫性を確保しながら、人員の動員を最適化し、ホリゾンタルで機敏な対応を実現します。
              </p>
              <p className="para">
                監視イベントを集約して、どのPagerDutyインシデントをチケット発行ツールに同期させるかを完全に制御します。PagerDutyでは、ベストプラクティスに基づいて構築されたプロセスを自動化するため、インシデント対応のより重要な部分に集中することができます。細かくスケーラブルな権限により、可視性を保持しながらもチームは独立した管理と運用が可能です。
              </p>
              <p className="para">
                従業員の働き方に縛りをかけずに情報を集中管理することで、あなたの組織は、人、プロセス、およびデータの間のサイロを排除するできます。
              </p>
            </div>
            <div className="media-contents-part">
              <img
                src="https://ik.imagekit.io/tcqno3mrr/PagerDuty/Product/IT_Operations/sync2x_f3d00b2015.png?ik-sdk-version=javascript-1.4.3&updatedAt=1672998586438&tr=w-720,q-100,f-webp"
                alt="知識は力なり"
              />
            </div>
          </div>
        </div>
      </div>
    </SiteWrapper>
  );
};
export default GeneratedProductItOperations;
